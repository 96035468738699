import { useAmplitude } from 'hooks/useAmplitude'
import { useHistory } from 'react-router-dom'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import {
  TRACKER_OPTIONS_DEFAULT,
  TRACKER_OPTIONS_FINANCING,
} from '../constants/TrackerOptions'

interface IUseFooterActionsProps {
  financingId: number | null
  orderId: number
  paymentLink: string | null
  paymentTypeSlug: string
  onClose: () => void
}

const ROUTES = {
  financing: (financingId: number) => `/financiamentos/valor/${financingId}`,
  orders: '/pedidos',
  orderDetails: (orderId: number) => `/pedidos/${orderId}`,
}

export const useFooterActions = ({
  financingId,
  orderId,
  paymentLink,
  paymentTypeSlug,
  onClose,
}: IUseFooterActionsProps) => {
  const history = useHistory()
  const { logEvent } = useAmplitude()

  const eventOptions = financingId
    ? TRACKER_OPTIONS_FINANCING
    : TRACKER_OPTIONS_DEFAULT

  const handleContinueText = (paymentTypeSlug: string, financingId: number | null) => {
    switch (paymentTypeSlug) {
      case 'financing':
        return financingId ? 'Atrelar pedido' : 'Acelerar confirmação'
        break;

      default:
        return 'Pagar agora!'
        break;

    }
  }

  const continueText = handleContinueText(paymentTypeSlug, financingId)

  const exitText = 'Agora não'

  const handleContinue = () => {
    const event = financingId
     ? ordersTracker.successOrderModal.valueFinancing.orderToValueFinancing
     : ordersTracker.successOrderModal.default.orderToPending

    const route = financingId
     ? ROUTES.financing(financingId)
     : ROUTES.orderDetails(orderId)

    if (paymentLink) {
      const newTab = window.open(paymentLink, "_blank");
      if (newTab) {
        newTab.focus();
      }
    }

    logEvent(event, eventOptions)
    history.push(route)

    onClose()
  }

  const handleExit = () => {
    const event = financingId
      ? ordersTracker.successOrderModal.valueFinancing.orderToOrderList
      : ordersTracker.successOrderModal.default.orderToOrderList

    logEvent(event, eventOptions)
    history.push(ROUTES.orders)
    onClose()
  }

  return { handleContinue, handleExit, continueText, exitText }
}
