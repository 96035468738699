import { useEffect } from 'react'
import { useFetchShowCEP } from 'domains/showcep'
import { useFormContext } from 'react-hook-form'
import { Container, CopyAddress } from './styles'
import { useCopyDeliveryAddress } from './hooks/useCopyDeliveryAddress'
import { type IShipmentFormValues } from '../../ShipmentForm.types'
import 'styles/grids.scss'
import { ufList } from 'utils/constants'
import { Input, InputMask, Checkbox } from '@77sol-ui/atoms'
import { Select } from 'components/forms/react-hook-form/Select'
import { RadioPersonType } from './components/RadioPersonType'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { NaturalPerson } from './components/NaturalPerson'
import { JuridicalPerson } from './components/JuridicalPerson'

export function BillingAddressForm(): JSX.Element {
  const {
    watch,
    setValue,
    clearErrors,
    control,
    register,
    formState: {
      errors: { billingAddress },
    },
  } = useFormContext<IShipmentFormValues>()

  const { isCheckedCopy, handleCheckedCopy } = useCopyDeliveryAddress()

  const cep = watch('billingAddress.cep')

  const { isFetching, cepData } = useFetchShowCEP(
    {
      cep,
    },
    {
      enabled: Boolean(cep),
    },
  )

  useEffect(() => {
    if (cepData) {
      clearErrors([
        'billingAddress.endereco',
        'billingAddress.bairro',
        'billingAddress.cidade',
        'billingAddress.estado',
      ])
      setValue('billingAddress.endereco', cepData.logradouro || '')
      setValue('billingAddress.bairro', cepData.bairro || '')
      setValue('billingAddress.cidade', cepData.cidade)
      setValue('billingAddress.estado', cepData.uf)
    }
  }, [cepData])

  const clientType = watch('billingAddress.client_type')

  return (
    <Container data-testid="billing-form-container">
      <h3>Dados de faturamento</h3>

      <div style={{ marginBottom: 16 }}>
        <RadioPersonType />
      </div>

      {clientType === PERSON_TYPE_ENUM.NATURAL ? (
        <NaturalPerson />
      ) : (
        <JuridicalPerson />
      )}

      <CopyAddress>
        <Checkbox
          id="copy-address-checkbox"
          data-testid="copy-address-checkbox"
          checked={isCheckedCopy}
          onCheckedChange={handleCheckedCopy}
          label="Copiar informações de endereço de entrega"
        />
      </CopyAddress>

      <div className="form-grid">
        <div className="g-xs-12 g-md-3">
          <InputMask.Masked
            mask="99999-999"
            label="CEP"
            placeholder="Digite o CEP"
            loading={isFetching}
            error={billingAddress?.cep?.message}
            {...register('billingAddress.cep')}
          />
        </div>

        <div className="g-xs-12 g-md-6">
          <Input
            label="Endereço"
            placeholder="Digite o endereço"
            error={billingAddress?.endereco?.message}
            {...register('billingAddress.endereco')}
          />
        </div>

        <div className="g-xs-4 g-md-3">
          <Input
            label="Número"
            placeholder="Digite o número"
            error={billingAddress?.numero?.message}
            {...register('billingAddress.numero')}
          />
        </div>

        <div className="g-xs-8 g-md-4">
          <Input
            label="Complemento"
            placeholder="Digite o complemento"
            required={false}
            error={billingAddress?.complemento?.message}
            {...register('billingAddress.complemento')}
          />
        </div>

        <div className="g-xs-12 g-md-3">
          <Input
            label="Bairro"
            placeholder="Digite o bairro"
            error={billingAddress?.bairro?.message}
            {...register('billingAddress.bairro')}
          />
        </div>

        <div className="g-xs-8 g-md-3">
          <Input
            label="Cidade"
            placeholder="Digite a cidade"
            error={billingAddress?.cidade?.message}
            {...register('billingAddress.cidade')}
          />
        </div>

        <div className="g-xs-4 g-md-2">
          <Select
            options={ufList}
            name="billingAddress.estado"
            control={control}
            label="UF"
            placeholder="Selecione o estado"
            error={billingAddress?.estado?.message}
          />
        </div>
      </div>
    </Container>
  )
}
