import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: 770px;
    max-height: 337px;
    border-radius: 4px;
  }
`
