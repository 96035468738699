import { useState } from 'react'
import { useToast } from '@77sol/core'
import { Button } from '@77sol-ui/atoms'
import { DeliveryAddressForm } from './components/DeliveryAddressForm'
import { BillingAddressForm } from './components/BillingAddressForm'
import { PaymentMethodForm } from './components/PaymentMethodForm'
import { SuccessOrderModal, AlterDeliveryModal } from '../Modals'
import { useStyles } from './styles'
import { type ShipmentFormProps } from './ShipmentForm.types'
import { useShipmentForm } from './hooks/useShipmentForm'

export function ShipmentForm({
  quotation,
  kit,
  quotationId,
  onCloseCheckoutModal,
  methods,
  cardFee,
}: ShipmentFormProps) {
  const classes = useStyles()
  const { onErrorOpenToast } = useToast()
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const [isAlterDeliveryModalOpen, setIsAlterDeliveryModalOpen] =
    useState(false)

  const {
    acceptNewDeliveryValue,
    handleCreateOrder,
    deliveryValue,
    isLoadingCreateOrder,
    addLojaNovoPedidoLoja,
    addProposalQuotationChangeDeliveryValue,
    cancelNewDeliveryValue,
  } = useShipmentForm({
    methods,
    quotationId,
    setIsAlterDeliveryModalOpen,
    quotation,
    setIsSuccessModalOpen,
  })

  const onSubmitFailed = (errors: any, { genericError = {} }: any) => {
    const {
      message = 'Preencha todos os campos obrigatórios (*) para continuar.',
    } = genericError
    onErrorOpenToast(message)
  }

  return (
    <>
      <div data-testid="shipment-form-container" className={classes.rightSide}>
        <form
          onSubmit={methods.handleSubmit(handleCreateOrder, onSubmitFailed)}
        >
          <DeliveryAddressForm kit={kit} />
          <BillingAddressForm />
          <PaymentMethodForm installmentsFee={cardFee.installments_fee} />

          <div className={classes.actions}>
            <Button
              id="shipmentForm_button_confirm"
              type="submit"
              isLoading={
                isLoadingCreateOrder ||
                addProposalQuotationChangeDeliveryValue.isLoading
              }
              fullWidth
            >
              Reservar equipamentos
            </Button>
          </div>
        </form>
      </div>

      <AlterDeliveryModal
        deliveryValue={deliveryValue}
        alterDeliveryValue={acceptNewDeliveryValue}
        cancelNewDeliveryValue={cancelNewDeliveryValue}
        isOpen={isAlterDeliveryModalOpen}
      />

      {isSuccessModalOpen && addLojaNovoPedidoLoja.data && (
        <SuccessOrderModal
          orderId={addLojaNovoPedidoLoja.data.order_id}
          paymentLink={addLojaNovoPedidoLoja.data.payment_link}
          paymentTypeSlug={addLojaNovoPedidoLoja.data.payment_method_slug}
          onClose={() => {
            onCloseCheckoutModal()
          }}
          isOpen={isSuccessModalOpen}
        />
      )}
    </>
  )
}
