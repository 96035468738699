import styled from 'styled-components'

export const Container = styled.section`
  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 16px 0;
  }

  > form {
    display: flex;
    gap: 32px;
    margin: 10px 0px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .credit-card {
    > div {
      margin: 16px 0;

      > div {
        display: 100%;

        @media (min-width: 768px) {
          width: 260px;
        }
      }
    }
  }
`
