import {
  GraduationCap,
  TruckIcon,
  Home,
  Landmark,
  Layers,
  Trophy,
  Zap,
  type LucideIcon,
  BookCheck,
} from 'lucide-react'
import { FeaturesRoutes } from 'acl/config/routes.types'
import { type ElementType } from 'react'

export interface INewSidebarNavMenuItems {
  title: string
  href: FeaturesRoutes
  icon: ElementType | LucideIcon
  signalType?: string
}

const INTEGRATOR_NAVBAR_MENU_ITEMS: INewSidebarNavMenuItems[] = [
  {
    title: 'Início',
    href: FeaturesRoutes.DASHBOARD,
    icon: Home,
  },
  {
    title: 'Ranking 77',
    href: FeaturesRoutes.RANKING77,
    icon: Trophy,
  },
  {
    title: 'Projetos',
    href: FeaturesRoutes.PROJETOS,
    icon: Layers,
  },
  {
    title: 'Financiamentos',
    href: FeaturesRoutes.FINANCIAMENTOS,
    icon: Landmark,
  },
  {
    title: 'Meus pedidos',
    href: FeaturesRoutes.PEDIDOS,
    icon: TruckIcon,
    signalType: 'orders',
  },
  {
    title: 'Universidade',
    href: FeaturesRoutes.UNIVERSIDADE77,
    icon: GraduationCap,
  },
  {
    title: 'Homologação',
    href: FeaturesRoutes.HOMOLOGACAO,
    icon: BookCheck,
  },
  {
    title: 'Equipamentos',
    href: FeaturesRoutes.EQUIPAMENTOS,
    icon: Zap,
  },
]

export { INTEGRATOR_NAVBAR_MENU_ITEMS }
