import styled from 'styled-components'

export const Container = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .form-grid {
    gap: 16px;
  }
`
