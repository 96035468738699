import { type ChangeEvent, useEffect, useState, useMemo } from 'react'
import { Button } from '@77sol/core'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useFormContext, useWatch } from 'react-hook-form'
import { usePendingOrderFields } from './hooks/usePendingOrderFields'
import { TextField } from 'components/Controllers/TextField'
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { DatePickerControlled } from 'components/Controllers/DatePicker'
import { NF_OPTIONS } from './constants/nfOptions'
import { FilledAlertInfoIcon } from '@77sol/icons/dist'
import { DEFAULT_NULL_FILES_VALUE, INTERNAL_STATUS_FORECAST } from './constants'

import {
  type IPendingFields,
  type IOrderPendingFieldsProps,
} from './OrderPendingsTab.types'

import UploadFileInput from 'components/UploadFileInput'
import SelectPayment from './components/SelectPayment'

import {
  CREDIT_CARD,
  EXCLUDE_PAYMENT_TYPES,
  FINANCING_77SOL,
  PAYMENT_TYPES_THAT_NEED_BILLING_LETTER,
} from './constants/paymentTypes'
import { parseBRL } from 'utils'
import { Tooltip } from '@material-ui/core'
import { palette } from '@77sol/styles'
import { formatPendingFields } from './utils'
import * as S from './styles'
import { useFocusInput } from './hooks/useFocusInput'

import { ModalSaveFormChanges } from './components/ModalSaveFormChanges'
import { useInterceptsClickAndNavigation } from 'hooks/useInterceptsClickAndNavigation'

export default function OrderPendingFields({
  disableFields,
  pendings,
  refetchOrder,
  order,
  paymentData,
}: IOrderPendingFieldsProps) {
  const [saveIsLoading, setSaveIsloading] = useState(false)
  const [sendIsLoading, setSendIsLoading] = useState(false)

  const {
    formRef,
    emailInputRef,
    phoneInputRef,
    birthInputRef,
    addressPaymentRef,
    nfOriginRef,
    nfValueRef,
    paymentTypeRef,
    billingLetterRef,
    titleRef,
  } = useFocusInput(order.status as number, paymentData)

  const {
    orderPendingFieldsMutation,
    orderPendingInfosFieldState,
    validatefilledFieldsState,
    calcInitialNfValueToCompare,
  } = usePendingOrderFields()

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
  } = useFormContext()

  const { logEvent } = useAmplitude()

  const allFields = useWatch({
    control,
  })

  const itemsInsurance: number =
    Number(order?.insurance_value) > 0 ? Number(order?.insurance_value) : 0

  const hasEquipmentsInsurance = order?.has_insurance

  const paymentType = watch('payment_type')

  const showInsuranceValue = useMemo(() => {
    return (
      Boolean(order?.financing_has_insurance) && paymentType === FINANCING_77SOL
    )
  }, [order?.financing_has_insurance, paymentType])

  const financingInsuranceValue = useMemo(() => {
    if (!showInsuranceValue) return 0

    const financingInsuranceValue = Number(order?.financing_insurance_value)

    if (!financingInsuranceValue) return 0

    return financingInsuranceValue
  }, [order?.financing_insurance_value, showInsuranceValue])

  const handleCalculateCharge = () => {
    if (allFields.payment_type !== CREDIT_CARD) return 0
    const totalNF = Number(allFields.nf_value) + itemsInsurance
    return (totalNF * 100) / (100 - Number(order?.card_fee)) - totalNF
  }

  const formAction = orderPendingInfosFieldState.all ? 'send' : 'save'
  const cardFee =
    order?.status_interno_id === INTERNAL_STATUS_FORECAST
      ? handleCalculateCharge()
      : order?.taxa_cartao

  const needShowBillingLetterField =
    PAYMENT_TYPES_THAT_NEED_BILLING_LETTER.includes(allFields.payment_type)

  useEffect(() => {
    validatefilledFieldsState(
      allFields as IPendingFields,
      formState,
      pendings?.completed ?? false,
    )
  }, [allFields, validatefilledFieldsState])

  useEffect(() => {
    if (!needShowBillingLetterField && formState.isDirty) {
      setValue('billing_letter', {
        file: null,
        name_file: null,
        url: null,
      })
    }
  }, [allFields.payment_type, formState.isDirty])

  async function handleSubmitForm() {
    const data = getValues()
    const formData = new FormData()

    const { hasChangedNfValue, initialNfValueWithoutTax } =
      calcInitialNfValueToCompare(
        pendings?.nf_value ?? 0,
        order?.taxa_cartao || 0,
        data.nf_value,
      )

    const paymentType = EXCLUDE_PAYMENT_TYPES.includes(data.payment_type)
      ? ''
      : data.payment_type

    const updatedPaymentType =
      (paymentType || data?.financial_institution) ?? ''

    formData.append('birth_date', data.birth_date)
    formData.append('customer_email', data.customer_email)
    formData.append('customer_phone', data.customer_phone)
    formData.append(
      'nf_value',
      hasChangedNfValue ? data.nf_value : initialNfValueWithoutTax,
    )
    formData.append('address_payment', data.address_payment.file ?? '')
    formData.append('billing_letter', data.billing_letter.file ?? '')
    formData.append('nf_origin', String(data.nf_origin))
    formData.append('order_id', String(order.id))
    formData.append('form_action', formAction)
    formData.append('payment_type', updatedPaymentType)

    try {
      await orderPendingFieldsMutation.mutateAsync(formData, {
        onSuccess: () => {
          onSuccessSubmitPendingFields()
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setSaveIsloading(false)
      setSendIsLoading(false)
    }
  }

  const handleTrackingPromptSend = () => {
    logEvent(ordersTracker.actions.sendPending, {
      origin: 'Prompt modal',
      formAction,
    })
  }

  const handleModalSave = async () => {
    handleTrackingPromptSend()
    await handleSubmit(handleSubmitForm)()
  }

  const {
    clickIntercepted: openModal,
    allowClick,
    handleClose,
  } = useInterceptsClickAndNavigation({
    isEnabled: formState.isDirty,
    excludeIds: [
      'pending-form',
      'notification-button',
      'cancel-order-modal',
      'order-selected-from-list',
      'save-form-modal',
      'order-pending-datepicker',
      'order-send-modal',
      'solleads-modal',
    ],
    interceptIds: ['order-header'],
    blockPopNavigation: true,
  })

  const onSuccessSubmitPendingFields = async () => {
    if (orderPendingInfosFieldState?.all) {
      await refetchOrder()
    }
    await allowClick()
    reset({}, { keepValues: true })
  }

  const hasAddressPaymentValue =
    allFields?.address_payment?.file?.name ??
    allFields?.address_payment?.name_file ??
    ''

  const hasBillingLetterValue =
    allFields?.billing_letter?.file?.name ??
    allFields?.billing_letter?.name_file ??
    ''

  const hasSomeFieldWithError = Object.keys(formState.errors).length > 0

  const nfFieldIsDisabled = allFields.nf_origin !== 'manual'

  const sendButtonIsDisabled =
    !orderPendingInfosFieldState.all ||
    orderPendingFieldsMutation.isLoading ||
    disableFields ||
    !formState.isDirty ||
    hasSomeFieldWithError

  const saveButtonIsDisabled =
    orderPendingFieldsMutation.isLoading ||
    disableFields ||
    !formState.isDirty ||
    !sendButtonIsDisabled ||
    hasSomeFieldWithError

  const disableFieldsForm =
    orderPendingFieldsMutation.isLoading || disableFields

  const totalNFValue = useMemo(() => {
    return (
      Number(allFields.nf_value) +
      cardFee +
      itemsInsurance +
      financingInsuranceValue
    )
  }, [allFields.nf_value, cardFee, itemsInsurance, financingInsuranceValue])

  const handleTrackingPromptDiscard = () => {
    logEvent(ordersTracker.actions.discardPending, {
      origin: 'Prompt modal',
      formAction,
    })
  }

  const handleModalDiscard = async (discard = true) => {
    handleTrackingPromptDiscard()
    await allowClick()
    discard &&
      reset(formatPendingFields(order.pendings as unknown as IPendingFields))
  }

  const handleCalculateNewNFValue = (value: ChangeEvent<HTMLInputElement>) => {
    clearErrors('nf_value')
    const nfValue = {
      valor_dos_equipamentos: order?.quotation?.equipment_value,
      valor_do_projeto: order?.quotation?.total_value,
      manual: order?.quotation?.equipment_value,
    }
    // @ts-expect-error nf_origin
    setValue('nf_value', nfValue?.[value])
  }

  const handleSave = () => {
    setSaveIsloading(true)
    logEvent(ordersTracker.actions.sendPending, {
      button: 'Salvar',
      origin: '/pedidos',
    })
    handleSubmitForm()
  }

  const handleSendToAnalysis = () => {
    setSendIsLoading(true)
    logEvent(ordersTracker.actions.sendPending, {
      button: 'Enviar para análise',
      origin: '/pedidos',
    })

    // @ts-expect-error handleSumitForm
    handleSubmit(handleSubmitForm())
  }

  return (
    <S.PendingsForm ref={formRef} id="pending-form">
      <S.ClusterContainer className="form-grid">
        <span className="g-xs-12 g-md-12 title" ref={titleRef}>
          Dados do destinatário
        </span>
        <TextField
          ref={emailInputRef}
          className="g-xs-12"
          control={control}
          name="customer_email"
          error={formState.errors.customer_email?.message as string}
          disabled={disableFieldsForm}
          label="E-mail (Opcional)"
        />

        <TextField
          ref={phoneInputRef}
          className="g-xs-12 g-md-5"
          control={control}
          label="Telefone (Opcional)"
          name="customer_phone"
          error={formState.errors.customer_phone?.message as string}
          mask="phoneNumber"
          disabled={disableFieldsForm}
        />

        <DatePickerControlled
          ref={birthInputRef}
          control={control}
          name="birth_date"
          disabled={disableFieldsForm}
          title="Data de Nasc. (Opcional)"
          className="g-xs-12 g-md-7"
          DialogProps={{
            id: 'order-pending-datepicker',
          }}
        />

        <UploadFileInput
          ref={addressPaymentRef}
          className="g-xs-12"
          title="Comprovante de endereço"
          value={hasAddressPaymentValue}
          onRemoveFile={() => {
            setValue('address_payment', DEFAULT_NULL_FILES_VALUE)
          }}
          onUploadFile={(file) => {
            setValue(
              'address_payment',
              {
                file,
                name_file: null,
                url: null,
              },
              { shouldDirty: true },
            )
          }}
          disabled={disableFieldsForm}
        />
      </S.ClusterContainer>

      <S.ClusterContainer className="form-grid">
        <span className="g-xs-12 g-md-12 title">Dados do pagamento</span>

        <DropdownControlled
          ref={nfOriginRef}
          className="g-xs-12 g-md-7"
          control={control}
          onChange={handleCalculateNewNFValue}
          value={allFields.nf_origin}
          label="Origem do valor da Nota Fiscal"
          fullWidth
          name="nf_origin"
          error={formState.errors.payment_type?.message as string}
          options={NF_OPTIONS}
          disabled={
            disableFieldsForm ||
            ['pending', 'paid', 'seen', 'rejected'].includes(
              paymentData?.status ?? '',
            )
          }
        />

        <S.NFValueInput
          ref={nfValueRef}
          className="g-xs-12 g-md-5"
          control={control}
          label="Valor Nota Fiscal"
          name="nf_value"
          mask="real"
          error={formState.errors.nf_value?.message as string}
          disabled={
            disableFieldsForm ||
            nfFieldIsDisabled ||
            ['pending', 'paid', 'seen', 'rejected'].includes(
              paymentData?.status ?? '',
            )
          }
        />
        <span className="g-xs-12 g-md-12 helperText">
          *Preencha esse campo com o valor que a 77Sol deverá emitir para você
          (ou o seu cliente) efetuar o pagamento. Caso queira adicionar o valor
          do seu serviço na Nota Fiscal, você pode criar uma proposta, ou
          acrescentar o valor diretamente no campo.
        </span>

        <SelectPayment
          ref={paymentTypeRef}
          className="g-xs-12 g-md-6"
          label="Forma de pagamento"
          name="payment_type"
          error={formState.errors.payment_type?.message as string}
          disabled={
            disableFieldsForm ||
            ['pending', 'paid', 'seen', 'rejected'].includes(
              paymentData?.status ?? '',
            )
          }
        />

        {(needShowBillingLetterField || hasBillingLetterValue) && (
          <UploadFileInput
            ref={billingLetterRef}
            className="g-xs-12"
            title="Carta de faturamento"
            value={hasBillingLetterValue}
            onRemoveFile={() => {
              setValue('billing_letter', DEFAULT_NULL_FILES_VALUE, {
                shouldDirty: true,
              })
            }}
            onUploadFile={(file) => {
              setValue(
                'billing_letter',
                {
                  file,
                  name_file: null,
                  url: null,
                },
                { shouldDirty: true },
              )
            }}
            disabled={disableFieldsForm}
          />
        )}
      </S.ClusterContainer>
      <S.NFContainer>
        {Boolean(hasEquipmentsInsurance) && (
          <div>
            <span>Seguro de Equipamentos:</span>
            <span>{parseBRL(itemsInsurance)}</span>
          </div>
        )}

        {showInsuranceValue && (
          <div>
            <span>Proteção de Financiamento:</span>
            <span>{parseBRL(financingInsuranceValue)}</span>
          </div>
        )}

        {allFields.payment_type === CREDIT_CARD && (
          <div>
            <span className="chargesContainer">
              Encargos
              <Tooltip title="Para pagamentos via cartão de crédito, é acrescido um encargo no valor final da Nota Fiscal.">
                <S.IconContainer>
                  <FilledAlertInfoIcon
                    width="16px"
                    color={palette.grayscale[400]}
                  />
                </S.IconContainer>
              </Tooltip>
            </span>
            <span>{parseBRL(cardFee)}</span>
          </div>
        )}

        <div>
          <span>Valor Nota Fiscal:</span>
          <span>{parseBRL(allFields.nf_value)}</span>
        </div>
        <div>
          <span className="total">TOTAL</span>
          <span>{parseBRL(totalNFValue)}</span>
        </div>
      </S.NFContainer>
      <S.ButtonsContainer>
        <Button
          size="small"
          onClick={handleSave}
          disabled={saveButtonIsDisabled}
          isLoading={saveIsLoading}
        >
          Salvar e terminar depois
        </Button>
        <Button
          size="small"
          onClick={handleSendToAnalysis}
          disabled={sendButtonIsDisabled}
          isLoading={sendIsLoading}
        >
          Enviar para análise
        </Button>
      </S.ButtonsContainer>

      {openModal && (
        <ModalSaveFormChanges
          id="save-form-modal"
          onClose={handleClose}
          onCloseCustomOpen={handleModalDiscard}
          onClickActionButton={handleModalSave}
          allFieldsFilled={orderPendingInfosFieldState?.all}
          hasError={hasSomeFieldWithError}
          isLoadingConfirmButton={orderPendingFieldsMutation.isLoading}
        />
      )}
    </S.PendingsForm>
  )
}
