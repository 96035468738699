import { useQueryClient } from '@tanstack/react-query'
import { type IShipmentFormValues } from 'containers/CheckoutModal/components/ShipmentForm/ShipmentForm.types'
import { type ProposalQuotationGETResponse } from 'domains/proposal/quotations_all/services/types'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { QUERY_KEYS } from 'services/constant'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { formatReal, parseBRLToNumber } from 'utils/format'

export const CalculateInsurance = () => {
  const checkoutModalStore = useCheckoutModalStore((state) => state)

  const queryClient = useQueryClient()
  const { watch } = useFormContext<IShipmentFormValues>()

  const laborValue = parseBRLToNumber(watch('kitValues.laborValue'))
  const hasInsurance = watch('kitValues.hasInsurance')

  const insuranceByPercent =
    queryClient.getQueryData<ProposalQuotationGETResponse>([
      QUERY_KEYS.PROPOSAL.QUOTATION,
      checkoutModalStore.quotationId,
    ])?.equipment_insurance_percent

  const insuranceByValue =
    queryClient.getQueryData<ProposalQuotationGETResponse>([
      QUERY_KEYS.PROPOSAL.QUOTATION,
      checkoutModalStore.quotationId,
    ])?.equipment_insurance_value

  const totalEquipment =
    Number(
      queryClient.getQueryData<ProposalQuotationGETResponse>([
        QUERY_KEYS.PROPOSAL.QUOTATION,
        checkoutModalStore.quotationId,
      ])?.quotation?.equipment_value,
    ) || 0

  const price = useMemo(() => {
    if (insuranceByPercent) {
      return (totalEquipment + laborValue) * (insuranceByPercent / 100)
    }

    if (insuranceByValue) return Number(insuranceByValue)

    return 0
  }, [insuranceByPercent, insuranceByValue, laborValue, totalEquipment])

  const total = useMemo(() => {
    if (hasInsurance) return formatReal(laborValue + totalEquipment + price)
    else return formatReal(laborValue + totalEquipment)
  }, [hasInsurance, laborValue, totalEquipment, price])

  const insurancePrice = formatReal(price)

  return { insurancePrice, total }
}
