import React from 'react'
import { Box } from '@material-ui/core'
import { palette } from '@77sol/styles'
import { formatDoc } from 'utils/format'
import { FinancialLabel } from 'components'
import { FilledAlertInfoIcon } from '@77sol/icons/dist'
import { Typography, Paper, Button, Spacer } from '@77sol/core'
import { handleFinancingName } from 'containers/FinancingContainer/components/FinancingSimulations/utils'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { PartialValueAlert } from './components/PartialValueAlert'

function FinancingCard({ values, onClick, isBinded, openBindFinancing }) {
  const { logEvent } = useAmplitude()

  const statusCanceled = values?.rowData?.status === 'Cancelado'
  const statusPreApproved = values?.rowData?.status === 'Pré-Aprovado'
  const notificationVisible = Boolean(values?.rowData?.notification)
  const buttonDisabled = statusCanceled || isBinded

  const handleBindFinancingOpen = () => {
    if (!buttonDisabled) {
      openBindFinancing()
    }
  }

  function handleClick(component) {
    logEvent(financingTracker.actions.accessFinancingSimulation, {
      origin: '/financiamentos',
      component,
    })

    onClick()
  }

  return (
    <Paper pointer boxShadow="small">
      <Box display="flex" flexDirection="column">
        <Box onClick={() => handleClick('Card')}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Typography size="small">
              {notificationVisible && (
                <FilledAlertInfoIcon
                  width="18"
                  color={palette.orange[300]}
                  style={{ marginRight: '12px' }}
                />
              )}
              #{values.rowData.id}
            </Typography>
            <Typography size="small">{values.simulation_date}</Typography>
          </Box>
          <Typography type="link" size="large">
            {values.bank ? handleFinancingName(values.bank) : values.financier}
          </Typography>
          {Boolean(values?.rowData?.has_partial_value) && (
            <PartialValueAlert
              approvedValue={values?.rowData?.value}
              requestedValue={values?.rowData?.requested_value}
            />
          )}
          <Paper>
            <Box
              {...(statusPreApproved && {
                display: 'flex',
                justifyContent: 'space-between',
              })}
            >
              <FinancialLabel status={values.rowData.status} />
              <Typography
                style={{ fontWeight: 'bold', maxWidth: '350px' }}
                colorWeight="800"
                color="primary"
                size="small"
              >
                {values.rowData.status_label}
              </Typography>
            </Box>
          </Paper>
          <Spacer size="20" direction="horizontal" />
          <Typography colorWeight="800" color="primary" size="small">
            Solicitante: {values.rowData.name_requester}
          </Typography>
          <Typography colorWeight="800" color="primary" size="small">
            CPF/CNPJ:{' '}
            {values.rowData.doc_requester
              ? formatDoc(values.rowData.doc_requester)
              : 'Não informado'}
          </Typography>
          <Spacer size="20" direction="horizontal" />
        </Box>
        {openBindFinancing && (
          <Button
            size="xsmall"
            color="tertiary"
            margin="vertical"
            variant="contained"
            disabled={buttonDisabled}
            onClick={handleBindFinancingOpen}
          >
            Atrelar a um pedido
          </Button>
        )}
        <Button
          size="xsmall"
          variant="contained"
          onClick={() => handleClick('Button')}
        >
          Acessar Financiamento
        </Button>
      </Box>
    </Paper>
  )
}

export default FinancingCard
