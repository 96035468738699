import { Switch } from 'components/forms/react-hook-form/Switch'
import { ShieldCheck } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import { type IShipmentFormValues } from 'containers/CheckoutModal/components/ShipmentForm/ShipmentForm.types'
import * as S from './styles'

interface IEquipmentInsuranceProps {
  insurancePrice: string
}

export const EquipmentInsurance = ({
  insurancePrice,
}: IEquipmentInsuranceProps) => {
  const { control } = useFormContext<IShipmentFormValues>()

  return (
    <S.Container>
      <div className="active">
        <div>
          <ShieldCheck size={16} />
          <h3>Garanta o seguro RD e RE</h3>
        </div>

        <Switch control={control} name="kitValues.hasInsurance" />
      </div>
      <div className="context">
        <p>
          Proteja os equipamentos do seu projeto com o seguro RD (Riscos
          Diversos) por 1 ano e sua equipe durante a instalação com o seguro RE
          (Riscos de Engenharia). Pagamento único. Confira a cobertura{' '}
          <a
            href="https://77solassets.s3.sa-east-1.amazonaws.com/77SOL_Seguros.pdf"
            target="_blank"
            rel="noreferrer"
          >
            aqui.
          </a>
        </p>
      </div>

      <div className="amount">
        <span>Valor do seguro</span>
        <span>
          <strong>+{insurancePrice}</strong>
        </span>
      </div>
    </S.Container>
  )
}
