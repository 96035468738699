const EXCLUDE_PAYMENT_TYPES = [
  'consorcio',
  'financiamento_77sol',
  'financiamento_cliente',
]

const CREDIT_CARD = 'Yapay Cartão de Crédito'
const EXTERNAL_FINANCING = 'financiamento_cliente'
const CONSORCIO = 'consorcio'
const FINANCING_77SOL = 'financiamento_77sol'

const PAYMENT_TYPES_THAT_NEED_BILLING_LETTER = [
  'financiamento_cliente',
  'consorcio',
]

const PAYMENT_TYPES_WITH_VINDI_LINK = [
  'Yapay Cartão de Crédito',
  'A vista (PIX)',
  'A vista (boleto)',
]

export {
  EXCLUDE_PAYMENT_TYPES,
  CREDIT_CARD,
  FINANCING_77SOL,
  EXTERNAL_FINANCING,
  CONSORCIO,
  PAYMENT_TYPES_THAT_NEED_BILLING_LETTER,
  PAYMENT_TYPES_WITH_VINDI_LINK,
}
