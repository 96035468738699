import * as S from './styles'
import { type IKitItem } from 'types/sizing/kit.types'

export interface KitItemProps {
  item: IKitItem
}

export function KitItem({ item }: KitItemProps): JSX.Element {
  return (
    <S.Container data-testid="container">
      <p>{item.titulo}</p>
      <div>
        <span>{item.qtde}</span>
        <span>&nbsp;un</span>
      </div>
    </S.Container>
  )
}
