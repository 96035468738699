import { colors } from '@77sol-ui/tokens'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  actions: {
    marginTop: '20px',

    '@media (max-width: 779px)': {
      marginBottom: '0px',
    },
    '@media (min-width: 780px)': {
      marginBottom: '30px',
    },
  },
  rightSide: {
    gridColumn: 'span 7',
    borderLeft: `1px solid ${colors.gray[150]}`,
    borderRadius: '0px 16px 16px 0px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '32px',

    '@media (max-width: 1279px)': {
      gridColumn: 'span 12',
    },

    '@media (min-width: 1280px)': {
      height: '100%',
    },
  },
})
