import * as Styled from './styles'
import { Alert } from '@77sol-ui/atoms'
import { parseBRL } from 'utils'

interface IPartialValueAlertProps {
  approvedValue: number
  requestedValue: number
}

export function PartialValueAlert({
  approvedValue,
  requestedValue,
}: IPartialValueAlertProps) {
  return (
    <Styled.Container>
      <Alert.Root variant="warning" rounded>
        <Alert.Icon variant="warning" />
        <Alert.Content>
          <Alert.Title title="A simulação foi pré-aprovada com um valor diferente." />
          <Alert.Description
            description={`O valor solicitado foi de ${parseBRL(
              requestedValue || 0,
            )} e o valor pré-aprovado pelo banco foi de ${parseBRL(
              approvedValue || 0,
            )}. Caso deseje dar continuidade ao processo de análise, por favor, solicite que seu cliente preencha e envie as informações no formulário.`}
          />
        </Alert.Content>
      </Alert.Root>
    </Styled.Container>
  )
}
