import * as yup from 'yup'
import { ValidationError } from 'yup'
import { billingAddressSchema } from './components/BillingAddressForm/schema.yup'
import { deliveryAddressSchema } from './components/DeliveryAddressForm/schema.yup'
import { kitValuesSchema } from '../OrderSummary/components/KitValues/schema.yup'
import { DIFFERENTS_STATES_ERROR } from 'containers/CheckoutModal/constants/genericErrors'

export const schema = yup
  .object()
  .shape({
    deliveryAddress: deliveryAddressSchema,
    billingAddress: billingAddressSchema,
    kitValues: kitValuesSchema,
    paymentMethod: yup.string().required(),
  })
  .test(
    DIFFERENTS_STATES_ERROR.KEY,
    DIFFERENTS_STATES_ERROR.DESCRIPTION,
    function ({ deliveryAddress, billingAddress }) {
      if (deliveryAddress.estado !== billingAddress.estado) {
        return new ValidationError([
          this.createError(DIFFERENTS_STATES_ERROR.GENERIC_ERROR),
          this.createError(DIFFERENTS_STATES_ERROR.DELIVERY_STATE),
          this.createError(DIFFERENTS_STATES_ERROR.BILLING_STATE),
        ])
      }

      return true
    },
  )
  .required()
