import { Switch as SwitchUi } from '@77sol-ui/atoms'
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form'

export interface ISwitchProps<T extends FieldValues> {
  control: Control<T, object>
  name: Path<T>
}

export const Switch = <T extends FieldValues>({
  control,
  name,
  ...props
}: ISwitchProps<T>) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <SwitchUi.Root
          {...props}
          onCheckedChange={(value) => {
            onChange(value)
          }}
          checked={value}
        >
          <SwitchUi.Thumb size="medium" />
        </SwitchUi.Root>
      )}
      control={control}
      name={name}
    />
  )
}
