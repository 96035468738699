import { Input, InputMask } from '@77sol-ui/atoms'
import { useFormContext } from 'react-hook-form'
import { type IShipmentFormValues } from '../../../ShipmentForm.types'

export function NaturalPerson() {
  const {
    register,
    formState: {
      errors: { billingAddress },
    },
  } = useFormContext<IShipmentFormValues>()

  return (
    <div className="form-grid">
      <div className="g-xs-12 g-md-4">
        <InputMask.Masked
          mask="999.999.999-99"
          label="CPF"
          placeholder="Digite o CPF"
          {...register('billingAddress.documento')}
          error={billingAddress?.documento?.message}
        />
      </div>

      <div className="g-xs-12 g-md-8">
        <Input
          label="Nome"
          placeholder="Digite o nome"
          {...register('billingAddress.nome')}
          error={billingAddress?.nome?.message}
        />
      </div>
    </div>
  )
}
