import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import yup from 'libs/yup'

const required = 'Campo obrigatório'

export const billingAddressSchema = yup
  .object()
  .shape(
    {
      nome: yup.string().required(required),
      documento: yup.string().when('client_type', {
        is: (value: PERSON_TYPE_ENUM) => value === PERSON_TYPE_ENUM.NATURAL,
        then: () =>
          yup.string().required('Insira um CPF.').cpf('Insira um CPF válido.'),
        otherwise: () =>
          yup
            .string()
            .required('Insira um CNPJ.')
            .cnpj('Insira um CNPJ válido.'),
      }),
      cep: yup.string().required(required).min(8, 'Insira um CEP válido'),
      endereco: yup.string().required(required),
      numero: yup.string().required(required),
      complemento: yup.string(),
      bairro: yup.string().required(required),
      cidade: yup.string().required(required),
      estado: yup
        .string()
        .required(required)
        .min(2, 'Insira um UF válido')
        .max(2),
    },
    [['documento', 'documento']],
  )
  .required()
