import { useAddLojaNovoPedidoLoja } from 'domains/loja/loja-novo-pedido-loja'
import { useAddProposalQuotationCalculateDeliveryValue } from 'domains/proposal/proposal-quotation-calculate-delivery-value'
import { useAddProposalQuotationChangeDeliveryValue } from 'domains/proposal/proposal-quotation-change-delivery-value'
import { cleanMask, formatReal, parseBRLToNumber } from 'utils/format'
import { useToast } from '@77sol/core'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useState } from 'react'
import { INITIAL } from 'containers/CheckoutModal/constants'
import { useByPassSaveProposal } from 'layouts/Dashboard/hooks/useByPassSaveProposal'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { type IShipmentFormValues } from '../ShipmentForm.types'
import { useFormContext } from 'react-hook-form'

interface Props {
  methods: any
  quotationId: number
  setIsAlterDeliveryModalOpen: (value: boolean) => void
  quotation: any
  setIsSuccessModalOpen: (value: boolean) => void
}

enum PaymentEnum {
  'pix' = 'A vista (PIX)',
  'billet' = 'A vista (boleto)',
  'credit-card' = 'Yapay Cartão de Crédito',
  'financing' = '',
}

export const useShipmentForm = ({
  methods,
  quotationId,
  setIsAlterDeliveryModalOpen,
  quotation,
  setIsSuccessModalOpen,
}: Props) => {
  const { getValues } = useFormContext<IShipmentFormValues>()

  const { logEvent } = useAmplitude()
  const { setOrderId, setPaymentLink, setPaymentMethodSlug } =
    useCheckoutModalStore()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false)
  const [deliveryValue, setAlterDelivery] = useState(INITIAL.ALTER_DELIVERY)

  const addProposalQuotationChangeDeliveryValue =
    useAddProposalQuotationChangeDeliveryValue()
  const addProposalQuotationCalculateDeliveryValue =
    useAddProposalQuotationCalculateDeliveryValue()
  const addLojaNovoPedidoLoja = useAddLojaNovoPedidoLoja()

  const continueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.continueQuotationWithChangesCallback,
  )

  const setOpenModalContinueWithChanged = useEquipmentContainerStore(
    (state) => state.setOpenModalContinueWithChanged,
  )

  const setContinueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.setContinueQuotationWithChangesCallback,
  )

  function paymentMethodTranslate(
    paymentMethod: keyof typeof PaymentEnum,
  ): string {
    return PaymentEnum[paymentMethod]
  }

  const validateDeliveryValue = async () => {
    return await addProposalQuotationCalculateDeliveryValue
      .mutateAsync({
        quotation_id: quotationId,
        cep: getValues('deliveryAddress.cep'),
        city: getValues('deliveryAddress.cidade'),
        state: getValues('deliveryAddress.estado'),
      })
      .then(({ after, before }) => {
        const values = {
          old: {
            price: formatReal(parseFloat(before.equipment_value)),
            uf: before.uf,
          },
          new: {
            price: formatReal(parseFloat(after.equipment_value)),
            uf: after.uf,
          },
        }

        if (values.old.price !== values.new.price) {
          setAlterDelivery({
            old_value: values.old,
            new_value: values.new,
          })
          setIsAlterDeliveryModalOpen(true)

          return false
        }

        const { subtotal, discount, delivery, equipment_value } = after
        return {
          total: equipment_value,
          subtotal,
          discount,
          delivery,
        }
      })
  }

  const acceptNewDeliveryValue = async () => {
    setIsAlterDeliveryModalOpen(false)

    await addProposalQuotationChangeDeliveryValue
      .mutateAsync({
        quotation_id: quotationId,
        cep: getValues('deliveryAddress.cep'),
        city: getValues('deliveryAddress.cidade'),
        state: getValues('deliveryAddress.estado'),
      })
      .then(() => {
        handleCreateOrder()
      })
  }

  const cancelNewDeliveryValue = () => {
    setIsAlterDeliveryModalOpen(false)
    setIsLoadingCreateOrder(false)
  }

  const { handleByPassSaveProposal } = useByPassSaveProposal()

  const handleCreateOrder = () => {
    logEvent(ordersTracker.actions.createOrder)
    setIsLoadingCreateOrder(true)
    validateDeliveryValue()
      .then(async (data: any) => {
        const newCreditCardFee =
          (data.total + parseBRLToNumber(getValues('kitValues.laborValue'))) *
          (getValues('kitValues.cardFee') /
            (100 - getValues('kitValues.cardFee')))

        if (parseBRLToNumber(getValues('kitValues.laborValue')) > data.total) {
          setIsLoadingCreateOrder(false)
          return onErrorOpenToast(
            'O valor da mão de obra não pode representar mais de 50% do valor do kit',
          )
        }

        if (data) {
          await addLojaNovoPedidoLoja.mutateAsync(
            {
              billingAddress: {
                ...getValues('billingAddress'),
                cep: cleanMask(getValues('billingAddress.cep')),
                documento: cleanMask(getValues('billingAddress.documento')),
              },
              deliveryAddress: {
                ...getValues('deliveryAddress'),
                cep: cleanMask(getValues('deliveryAddress.cep')),
              },
              order: {
                total: data.total,
                subtotal: data.subtotal,
                desconto: data.discount,
                frete_valor: data.delivery || quotation.delivery_value,
                taxa_cartao: newCreditCardFee,
                quotation_id: quotationId,
                has_equipment_insurance: getValues('kitValues.hasInsurance'),
                payment_method: paymentMethodTranslate(
                  methods.getValues('paymentMethod'),
                ),
                payment_method_slug: getValues('paymentMethod'),
                labor_value: parseBRLToNumber(
                  getValues('kitValues.laborValue'),
                ),
                nf_value:
                  data.total +
                  parseBRLToNumber(getValues('kitValues.laborValue')) +
                  newCreditCardFee,
                installments: getValues('kitValues.installments'),
              },
              confirm_quotation_changes:
                continueQuotationWithChangesCallback !== null,
            },
            {
              onSuccess: (data) => {
                setIsSuccessModalOpen(true)
                onSuccessOpenToast('Reserva realizada com sucesso!')
                handleByPassSaveProposal(true)
                setOrderId(data?.order_id)
                setPaymentLink(data?.payment_link)
                setPaymentMethodSlug(data?.payment_method_slug)
                setContinueQuotationWithChangesCallback(null)
                setOpenModalContinueWithChanged(false)
              },
              onSettled: () => {
                setIsLoadingCreateOrder(false)
              },
            },
          )
        }
      })
      .catch((error) => onErrorOpenToast(error?.message))
  }

  return {
    acceptNewDeliveryValue,
    handleCreateOrder,
    isLoadingCreateOrder,
    deliveryValue,
    addLojaNovoPedidoLoja,
    addProposalQuotationChangeDeliveryValue,
    cancelNewDeliveryValue,
  }
}
