import { useQuery } from '@tanstack/react-query'
import { useToast } from '@77sol/core'
import { showCEP } from '../services/api'
import { type ShowCEPParams } from '../services/types'
import { QUERY_KEYS } from 'services/constant'

export const useFetchShowCEP = (params: ShowCEPParams, options?: any) => {
  const { onErrorOpenToast } = useToast()

  const formatedParams = { ...params, cep: params.cep.replace('-', '') }

  const { data, isLoading, isSuccess, isFetching, isError } = useQuery({
    queryKey: [QUERY_KEYS.SHOWCEP._, formatedParams],
    queryFn: async () => await showCEP.get(formatedParams),
    enabled: formatedParams.cep.length === 8 && options?.enabled,
    onError: () =>
      onErrorOpenToast('Ocorreu um erro ao tentar encontrar o CEP digitado!'),
    staleTime: 1000 * 60 * 10, // 10 minutes
  })

  return { cepData: data, isLoading, isSuccess, isFetching, isError }
}
