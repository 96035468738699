import { useQuery } from '@tanstack/react-query'
import { storeCardFee } from '../services/api'
import { GET_CARD_FEE } from '../constants/endpointKeys'

export const useFetchCardFee = () => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [GET_CARD_FEE],
    queryFn: async () => await storeCardFee.get(),
  })

  return { cardFeeData: data, isLoading, isSuccess, isError }
}
