import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(var(--app-height) - 100px)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '24px',
    paddingTop: '0px',
    overflow: 'auto',
    height: '100%',
    [breakpoints.down('lg')]: {
      flexDirection: 'column',
      paddingTop: '0px',
    },
  },
  firstContainer: {
    padding: '24px 24px 12px 24px',
    paddingTop: '0px',
  },
  timelineContainer: {
    [breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  tabsContainer: {
    width: 'auto',
    margin: '10px auto',
    [breakpoints.down('lg')]: {
      width: '100%',
    },
    [breakpoints.up('xl')]: {
      margin: '20px',
    },
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clipboardInput: {
    width: '100%',
  },
}))

export default useStyles
