import * as S from './styles'

export function BannerWithImage() {
  
  return (
    <S.ImageBanner>
      <picture>
        <source
          srcSet={`https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/quotation/banner_quotation_mobile.png`}
          media="(max-width: 1100px)"
        />
        <img
          src={`https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/quotation/banner_quotation.png`}
          alt="Checkout"
        />
      </picture>
    </S.ImageBanner>
  )
}
