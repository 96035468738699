import { useFormContext } from 'react-hook-form'
import { parseBRL } from 'utils'
import { Container } from './styles'
import {
  type IShipmentFormValues,
  type IPaymentMethodFormProps,
} from '../../ShipmentForm.types'
import 'styles/grids.scss'
import { Radio } from '@77sol-ui/atoms'
import { Select } from 'components/forms/react-hook-form/Select'
import { parseBRLToNumber } from 'utils/format'
import { CalculateInsurance } from 'containers/CheckoutModal/components/OrderSummary/components/EquipmentInsurance/hook/CalculateInsurance'

export function PaymentMethodForm({
  installmentsFee,
}: IPaymentMethodFormProps): JSX.Element {
  const { watch, setValue, control } = useFormContext<IShipmentFormValues>()

  const { total } = CalculateInsurance()

  const selectedPaymentMethod = watch('paymentMethod')
  const cardFeeValue = watch('kitValues.cardFee')
  const creditCardCharges = watch('kitValues.creditCardCharges')

  const handleCalculateInstallment = (installment: number) => {
    const installmentDetails = installmentsFee.find(
      (fee) => fee.type === installment + '_installment_fee',
    )
    const formatedFee = installmentDetails?.value.replace(',', '.') ?? '0'
    const totalValueWithFee =
      (parseBRLToNumber(total) + creditCardCharges) *
      (1 + parseFloat(formatedFee) / 100)
    return parseBRL(totalValueWithFee / installment)
  }

  const handleChangePaymentMethod = (method: string) => {
    setValue('paymentMethod', method)
    if (method === 'credit-card') {
      setValue(
        'kitValues.creditCardCharges',
        parseBRLToNumber(total) * (cardFeeValue / (100 - cardFeeValue)),
      )
    } else {
      setValue('kitValues.creditCardCharges', 0)
    }
  }

  return (
    <Container>
      <h3>Método de pagamento</h3>

      <div className="form" data-testid="payment-method-form-container">
        <Radio.Root>
          <Radio.Group name="value">
            <Radio.Item
              name="billet"
              value="billet"
              checked={selectedPaymentMethod === 'billet'}
              onClick={() => {
                handleChangePaymentMethod('billet')
              }}
              data-testid="billet-method-checkbox"
            >
              Boleto
            </Radio.Item>
            <Radio.Item
              name="pix"
              value="pix"
              checked={selectedPaymentMethod === 'pix'}
              onClick={() => {
                handleChangePaymentMethod('pix')
              }}
              data-testid="pix-method-checkbox"
            >
              PIX
            </Radio.Item>
            <Radio.Item
              name="credit-card"
              value="credit-card"
              checked={selectedPaymentMethod === 'credit-card'}
              onClick={() => {
                handleChangePaymentMethod('credit-card')
              }}
              data-testid="credit-card-method-checkbox"
            >
              Cartão de crédito
            </Radio.Item>
            <Radio.Item
              name="financing"
              value="financing"
              checked={selectedPaymentMethod === 'financing'}
              onClick={() => {
                handleChangePaymentMethod('financing')
              }}
              data-testid="financing-method-checkbox"
            >
              Financiamento
            </Radio.Item>
          </Radio.Group>
        </Radio.Root>
      </div>

      {selectedPaymentMethod === 'credit-card' && (
        <div className="credit-card">
          <h3>Parcelamento</h3>

          <div>
            <Select
              options={[
                {
                  label:
                    '1x de ' + handleCalculateInstallment(1) + ' sem juros',
                  value: '1',
                },
                {
                  label:
                    '2x de ' + handleCalculateInstallment(2) + ' com juros',
                  value: '2',
                },
                {
                  label:
                    '3x de ' + handleCalculateInstallment(3) + ' com juros',
                  value: '3',
                },
                {
                  label:
                    '4x de ' + handleCalculateInstallment(4) + ' com juros',
                  value: '4',
                },
                {
                  label:
                    '5x de ' + handleCalculateInstallment(5) + ' com juros',
                  value: '5',
                },
                {
                  label:
                    '6x de ' + handleCalculateInstallment(6) + ' com juros',
                  value: '6',
                },
                {
                  label:
                    '7x de ' + handleCalculateInstallment(7) + ' com juros',
                  value: '7',
                },
                {
                  label:
                    '8x de ' + handleCalculateInstallment(8) + ' com juros',
                  value: '8',
                },
                {
                  label:
                    '9x de ' + handleCalculateInstallment(9) + ' com juros',
                  value: '9',
                },
                {
                  label:
                    '10x de ' + handleCalculateInstallment(10) + ' com juros',
                  value: '10',
                },
                {
                  label:
                    '11x de ' + handleCalculateInstallment(11) + ' com juros',
                  value: '11',
                },
                {
                  label:
                    '12x de ' + handleCalculateInstallment(12) + ' com juros',
                  value: '12',
                },
              ]}
              name="kitValues.installments"
              control={control}
              label="Numero de parcelas"
            />
          </div>
        </div>
      )}
    </Container>
  )
}
