import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Tooltip } from '@material-ui/core'
import { palette } from '@77sol/styles'
import { FilledAlertInfoIcon } from '@77sol/icons/dist'
import { KitItem } from '../../components/KitItem'
import { type IShipmentFormValues } from '../../../ShipmentForm/ShipmentForm.types'
import { type KitValuesProps } from './KitValues.types'
import * as S from './styles'
import { EquipmentInsurance } from '../EquipmentInsurance'
import { InputMask } from '@77sol-ui/atoms'
import { parseBRLToNumber, formatReal } from 'utils/format'
import { CalculateInsurance } from '../EquipmentInsurance/hook/CalculateInsurance'

export function KitValues({ kit }: KitValuesProps): JSX.Element {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: {
      errors: { kitValues },
    },
  } = useFormContext<IShipmentFormValues>()

  const laborValue = parseBRLToNumber(watch('kitValues.laborValue'))
  const equipmentsValue = watch('kitValues.equipmentsValue')
  const cardFeeValue = watch('kitValues.cardFee')
  const creditCardChargesValue = watch('kitValues.creditCardCharges')
  const paymentMethod = watch('paymentMethod')

  const { insurancePrice, total } = CalculateInsurance()

  useEffect(() => {
    setValue('kitValues.totalValue', equipmentsValue + laborValue)

    if (paymentMethod === 'credit-card') {
      setValue(
        'kitValues.creditCardCharges',
        parseBRLToNumber(total) * (cardFeeValue / (100 - cardFeeValue)),
      )
    } else {
      setValue('kitValues.creditCardCharges', 0)
    }

    if (laborValue > equipmentsValue) {
      setError('kitValues.laborValue', {
        type: 'manual',
        message:
          'O valor da mão de obra não pode representar mais de 50% do valor do kit',
      })
    } else {
      clearErrors('kitValues.laborValue')
    }
  }, [laborValue, setError, total])

  const equipamentAmount = formatReal(equipmentsValue)
  const creditCardFees = formatReal(creditCardChargesValue)

  return (
    <S.Container>
      <h3>Equipamentos</h3>

      {kit.map((item) => (
        <KitItem key={item.id} item={item} />
      ))}

      <div className="summary">
        <div data-testid="total-equipaments" className="summaryValue">
          <span>Valor dos equipamentos</span>
          <span>
            <b>{equipamentAmount}</b>
          </span>
        </div>

        <div data-testid="total-container" className="summaryValue">
          <span>Valor da mão de obra</span>

          <div className="laborValue">
            <InputMask.Money
              {...register('kitValues.laborValue')}
              error={kitValues?.laborValue?.message}
            />
          </div>
        </div>

        <EquipmentInsurance insurancePrice={insurancePrice} />

        <div data-testid="total-container" className="summaryValue">
          <span>Total (Nota Fiscal)</span>
          <span>
            <strong>{total}</strong>
          </span>
        </div>

        {creditCardChargesValue > 0 && (
          <div data-testid="total-container" className="summaryValue">
            <div>
              <span>
                Taxa do Cartão de Crédito
                <Tooltip title="Para pagamentos via CC, na cobrança será acrescido o valor da taxa do cartão. Esse valor não deve ser lançado na NF.">
                  <S.IconContainer>
                    <FilledAlertInfoIcon
                      width="16px"
                      color={palette.grayscale[400]}
                    />
                  </S.IconContainer>
                </Tooltip>
              </span>
            </div>
            <span>
              <strong>{creditCardFees}</strong>
            </span>
          </div>
        )}
      </div>
    </S.Container>
  )
}
