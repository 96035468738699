import { Input, InputMask } from '@77sol-ui/atoms'
import { useFormContext } from 'react-hook-form'
import { type IShipmentFormValues } from '../../../ShipmentForm.types'
import { useEffect } from 'react'
import { useFetchCnpjInfo } from 'domains/document/hooks/useDocument'
import { formatCEP, formatDoc } from 'utils/format'

export function JuridicalPerson() {
  const {
    register,
    formState: {
      errors: { billingAddress },
    },
    watch,
    reset,
    setValue,
  } = useFormContext<IShipmentFormValues>()

  const document = watch('billingAddress.documento')

  const formatedDocument = document
    .replace('.', '')
    .replace('/', '.')
    .replace('-', '')

  const { documentInfo, isFetchingDocument } = useFetchCnpjInfo(
    {
      document: formatedDocument,
    },
    {
      enabled: Boolean(formatedDocument),
    },
  )

  useEffect(() => {
    reset({}, { keepValues: true })
    if (documentInfo) {
      setValue('billingAddress.documento', formatDoc(documentInfo.cnpj))
      setValue('billingAddress.nome', documentInfo.nome_empresarial || '')
      setValue('billingAddress.endereco', documentInfo.logradouro)
      setValue('billingAddress.numero', documentInfo.numero)
      setValue('billingAddress.complemento', documentInfo.complemento)
      setValue('billingAddress.bairro', documentInfo.bairro)
      setValue('billingAddress.estado', documentInfo.uf)
      setValue('billingAddress.cidade', documentInfo.municipio)
      setValue('billingAddress.cep', formatCEP(documentInfo.cep))
    }
  }, [documentInfo])

  return (
    <div className="form-grid">
      <div className="g-xs-12 g-md-4">
        <InputMask.Masked
          mask="99.999.999/9999-99"
          label="CNPJ"
          placeholder="Digite o CNPJ"
          loading={isFetchingDocument}
          {...register('billingAddress.documento')}
          error={billingAddress?.documento?.message}
        />
      </div>

      <div className="g-xs-12 g-md-8">
        <Input
          label="Razão Social"
          placeholder="Digite a razão social"
          {...register('billingAddress.nome')}
          error={billingAddress?.nome?.message}
        />
      </div>
    </div>
  )
}
