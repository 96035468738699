import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { formatReal } from 'utils/format'
import { useStyles } from './styles'
import { KitValues } from './components/KitValues'
import { type OrderSummaryProps } from './OrderSummary.types'
import { Banner } from '../Banner'
import { type IShipmentFormValues } from '../ShipmentForm/ShipmentForm.types'

export function OrderSummary({
  kit,
  equipmentsValue,
  laborValue,
  cardFee,
}: OrderSummaryProps): JSX.Element {
  const classes = useStyles()

  const { setValue } = useFormContext<IShipmentFormValues>()

  useEffect(() => {
    setValue('kitValues.equipmentsValue', parseFloat(equipmentsValue))
    setValue('kitValues.laborValue', formatReal(laborValue))
    setValue(
      'kitValues.totalValue',
      parseFloat(equipmentsValue) + parseFloat(laborValue),
    )
    setValue('kitValues.cardFee', parseFloat(cardFee))
    setValue('kitValues.installments', '1')
  }, [])

  return (
    <div data-testid="order-summary-container" className={classes.leftSide}>
      <div>
        <form>
          <KitValues kit={kit} />
        </form>
        <Banner />
      </div>
    </div>
  )
}
