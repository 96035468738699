/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from '../layouts/Auth'
import PreviewLayout from '../layouts/Preview'
import PedidosView from '../views/Pedidos'
import StoreView from '../views/Store'
import { type INestedRoute } from 'types/routes'
import { errorRoutes } from './errorRoutes'
import { Quotation } from 'views/Quotation'
import DashboardLayout from '../layouts/Dashboard'
import { HomeLayout } from 'layouts/Home'
import { Home } from 'views/Home'

const routes: INestedRoute[] = [
  {
    path: '/dashboard',
    exact: true,
    component: () => <Redirect to="/" />,
  },
  {
    path: '/dashboard/cotar',
    exact: true,
    component: () => <Redirect to="?quotationModal" />,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        component: () => <Redirect to="/erro/404" />,
      },
    ],
  },
  {
    path: '/visualizar',
    component: PreviewLayout,
    routes: [
      {
        path: '/visualizar/cotacaoV2/:id',
        exact: true,
        component: lazy(() => import('views/Preview/CotacaoV2')),
      },
      {
        path: '/visualizar/pedido/:id',
        exact: true,
        component: lazy(() => import('views/OrderTracking')),
      },
      {
        component: () => <Redirect to="/erro/404" />,
      },
    ],
  },
  { ...errorRoutes },
  {
    path: '/',
    component: HomeLayout,
    exact: true,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/rank-77',
        exact: true,
        component: lazy(() => import('views/SpecialEvents/WorldCupEvent')),
      },
      {
        path: '/calendario',
        exact: true,
        component: lazy(() => import('views/Calendar')),
      },
      {
        path: '/dimensionar',
        exact: true,
        component: lazy(() => import('views/DimensionarV2')),
      },
      {
        path: '/propostas-antigas',
        exact: true,
        component: lazy(() => import('views/OldProposals')),
      },
      {
        path: '/projetos',
        exact: true,
        component: lazy(() => import('views/Projects')),
      },
      {
        path: '/projetos/propostas',
        exact: true,
        component: lazy(() => import('views/ProjectProposals')),
      },
      {
        path: [
          '/financiamentos/:tab?',
          '/financiamentos/:tab/:financingRequestId?',
          '/financiamentos/:tab/:financingRequestId/simulacao/:simulationId?',
        ],
        exact: true,
        component: lazy(() => import('views/Financing')),
      },
      {
        path: '/site-integrador',
        exact: true,
        component: lazy(() => import('views/IntegratorWebSite')),
      },
      {
        path: '/site-integrador/:tab',
        exact: true,
        component: lazy(() => import('views/IntegratorWebSite')),
      },
      {
        path: '/time',
        exact: true,
        component: lazy(() => import('views/MyTeam')),
      },
      {
        path: '/time/:tab',
        exact: true,
        component: lazy(() => import('views/MyTeam')),
      },
      {
        path: '/equipamentos',
        exact: true,
        component: StoreView,
      },
      {
        path: '/equipamentos/:tab',
        exact: true,
        component: StoreView,
      },
      {
        path: '/pedidos',
        exact: true,
        component: PedidosView,
      },
      {
        path: '/cotar/:dimensioningId',
        exact: true,
        component: Quotation,
      },
      {
        path: '/universidade-77',
        exact: true,
        component: lazy(() => import('views/University')),
      },
      {
        path: '/homologacao-77',
        exact: true,
        component: lazy(() => import('views/Campaigns/Homologation')),
      },
      {
        path: '/pedidos/:orderId',
        exact: true,
        component: PedidosView,
      },
      {
        path: '/configuracoes',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        path: '/configuracoes/:tab',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        component: () => <Redirect to="/erro/404" />,
      },
    ],
  },
]

export default routes
