import { ModalContent, StyledOverlay } from './styles'
import { type AlterDeliveryModalProps } from './AlterDeliveryModal.types'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { Modal } from '@77sol-ui/molecules'
import { AlertCircle } from 'lucide-react'
import { Button } from '@77sol-ui/atoms'

export function AlterDeliveryModal({
  isOpen,
  deliveryValue,
  alterDeliveryValue,
  cancelNewDeliveryValue,
}: AlterDeliveryModalProps): JSX.Element {
  const { logEvent } = useAmplitude()

  const handleCancel = () => {
    logEvent(ordersTracker.actions.cancelShippingChange, {
      origin: '(Modal) Reservar Pedido',
    })

    cancelNewDeliveryValue()
  }

  const handleConfirm = () => {
    logEvent(ordersTracker.actions.confirmShippingChange, {
      origin: '(Modal) Reservar Pedido',
    })

    alterDeliveryValue()
  }

  const { new_value, old_value } = deliveryValue

  return (
    <Modal.Root open={isOpen} onOpenChange={handleCancel}>
      <Modal.Portal>
        <StyledOverlay id="modal-alter-delivery-overlay" />
        <Modal.Wrapper
          id="modal-alter-delivery"
          maxWidth="700px"
          maxHeight="95vh"
          zIndex={1500}
          data-testid="container"
        >
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={AlertCircle} />
            <Modal.Title>
              Você realizou uma alteração no UF da cotação. Deseja continuar?
            </Modal.Title>
            <Modal.Description>
              <strong>
                Antes: {old_value.uf}. Agora: {new_value.uf}.
              </strong>{' '}
              Essa mudança atualizou o frete do kit. Você pode continuar ou
              voltar e editar a UF (obs: isso pode gerar impacto no valor do
              Seguro e na Taxa do CC, se em uso).
            </Modal.Description>
          </Modal.Header>

          <ModalContent>
            <div className="compare-price-wrapper">
              <div className="old-price">
                <p>Valor dos equipamentos anterior</p>
                <p className="price">{old_value.price}</p>
              </div>

              <div className="current-price">
                <p>Valor dos equipamentos atual</p>
                <p className="price">{new_value.price}</p>
              </div>
            </div>
          </ModalContent>

          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline" onClick={handleCancel}>
                Voltar
              </Button>
            </Modal.Close>
            <Button fullWidth onClick={handleConfirm}>
              Continuar
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
