import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export const INITIAL = {
  ALTER_DELIVERY: {
    old_value: {
      price: '',
      uf: '',
    },
    new_value: {
      price: '',
      uf: '',
    },
  },
  DELIVERY_ADDRESS: {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  },
  BILLING_ADDRESS: {
    nome: '',
    documento: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    client_type: PERSON_TYPE_ENUM.NATURAL,
  },
}
