import API from 'api'

interface Fee {
  id: number
  type: string
  value: string
}

type cardFeeGETResponse = {
  card_fee: string
  installments_fee: Fee[]
}

export const storeCardFee = {
  get: async () => {
    return await API.get<cardFeeGETResponse>(
      '/admin/card-fee',
    ).then(({ data }) => data)
  },
}
