import { useEffect } from 'react'
import { useFetchShowCEP } from 'domains/showcep'
import { useFormContext } from 'react-hook-form'
import { type DeliveryAddressFormProps } from './DeliveryAddressForm.types'
import { Input, InputMask } from '@77sol-ui/atoms'
import { type IShipmentFormValues } from '../../ShipmentForm.types'
import { Container } from './styles'
import 'styles/grids.scss'
import { ufList } from 'utils/constants'
import { Select } from 'components/forms/react-hook-form/Select'

export function DeliveryAddressForm({
  kit,
}: DeliveryAddressFormProps): JSX.Element {
  const {
    watch,
    setValue,
    control,
    register,
    clearErrors,
    formState: {
      errors: { deliveryAddress },
    },
  } = useFormContext<IShipmentFormValues>()

  const providerId = kit.find((item) => Boolean(item))?.fornecedor
  const cep = watch().deliveryAddress.cep

  const { cepData, isFetching } = useFetchShowCEP(
    {
      cep,
      providerId,
    },
    {
      enabled: Boolean(cep),
    },
  )

  useEffect(() => {
    if (cepData) {
      clearErrors([
        'deliveryAddress.endereco',
        'deliveryAddress.bairro',
        'deliveryAddress.cidade',
        'deliveryAddress.estado',
      ])
      setValue('deliveryAddress.endereco', cepData.logradouro || '')
      setValue('deliveryAddress.bairro', cepData.bairro || '')
      setValue('deliveryAddress.cidade', cepData.cidade)
      setValue('deliveryAddress.estado', cepData.uf)
    }
  }, [cepData])

  return (
    <Container>
      <h3>Dados de entrega</h3>

      <div className="form-grid">
        <div className="g-xs-12 g-md-3">
          <InputMask.Masked
            mask="99999-999"
            label="CEP"
            placeholder="Digite o CEP"
            loading={isFetching}
            error={deliveryAddress?.cep?.message}
            {...register('deliveryAddress.cep')}
          />
        </div>

        <div className="g-xs-12 g-md-6">
          <Input
            label="Endereço"
            placeholder="Digite o endereço"
            error={deliveryAddress?.endereco?.message}
            {...register('deliveryAddress.endereco')}
          />
        </div>

        <div className="g-xs-4 g-md-3">
          <Input
            label="Número"
            placeholder="Digite o número"
            error={deliveryAddress?.numero?.message}
            {...register('deliveryAddress.numero')}
          />
        </div>

        <div className="g-xs-8 g-md-4">
          <Input
            label="Complemento"
            placeholder="Digite o complemento"
            required={false}
            error={deliveryAddress?.complemento?.message}
            {...register('deliveryAddress.complemento')}
          />
        </div>

        <div className="g-xs-12 g-md-3">
          <Input
            label="Bairro"
            placeholder="Digite o bairro"
            error={deliveryAddress?.bairro?.message}
            {...register('deliveryAddress.bairro')}
          />
        </div>

        <div className="g-xs-8 g-md-3">
          <Input
            label="Cidade"
            placeholder="Digite a cidade"
            error={deliveryAddress?.cidade?.message}
            {...register('deliveryAddress.cidade')}
          />
        </div>

        <div className="g-xs-4 g-md-2">
          <Select
            options={ufList}
            name="deliveryAddress.estado"
            control={control}
            label="UF"
            placeholder="Selecione o estado"
            error={deliveryAddress?.estado?.message}
          />
        </div>
      </div>
    </Container>
  )
}
