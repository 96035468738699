import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;
  padding: 4px 0;

  p {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.gray[500]};
  }

  > div {
    display: flex;
    align-items: baseline;
    margin-left: 20px;

    span {
      font-size: 14px;
    }
  }
`
