import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Container = styled.section`
  background: ${colors.blue[50]};
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${colors.blue[200]};
  margin-top: 16px;

  .active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    margin-bottom: 16px;

    > div {
      display: flex;
      gap: 6px;

      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .context {
    margin-bottom: 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;

      a {
        color: ${colors.blue[300]};
        text-decoration: underline;
      }
    }
  }

  .amount {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
    }
  }
`
