import * as yup from 'yup'

export const kitValuesSchema = yup
  .object()
  .shape({
    laborValue: yup.string(),
    equipmentsValue: yup.number(),
    totalValue: yup.number(),
    creditCardCharges: yup.number(),
    cardFee: yup.number(),
    installments: yup.string(),
    hasInsurance: yup.boolean(),
  })
  .required()
