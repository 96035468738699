import { forwardRef, type ChangeEvent, type InputHTMLAttributes } from 'react'
import { Dropdown } from '@77sol/core'
import { type Control, Controller } from 'react-hook-form'

interface IDropdownOptions {
  label: string
  value: string
}

interface OverlapingTextFieldProps {
  size?: string | number
}

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

interface DropdownProps extends SimpleSpread<InputHTMLAttributes<HTMLInputElement>, OverlapingTextFieldProps> {
  name: string
  control: Control<any>
  label: string
  error?: string
  options: IDropdownOptions[]
  className?: string
  fullWidth?: boolean
}

interface IHandleChangeParams {
  formChange: (e: ChangeEvent<HTMLInputElement>) => void
  e: ChangeEvent<HTMLInputElement>
}

export const DropdownControlled = forwardRef<HTMLElement, DropdownProps>(
  (
    { name, control, label, error, options, className, onChange, ...rest },
    ref,
  ) => {
    const handleChange = ({ formChange, e }: IHandleChangeParams) => {
      formChange(e)
      if (onChange) onChange(e)
    }

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: formChange, value }, ...field }) => (
          <Dropdown
            role="listbox"
            className={className}
            title={label}
            validationState={error ? 'invalid' : 'default'}
            helperText={error}
            options={options}
            value={value}
            initialValue={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange({ formChange, e })
            }}
            {...rest}
            {...field}
            ref={ref}
          />
        )}
      />
    )
  },
)
