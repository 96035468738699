import { CloseXIcon } from '@77sol/icons/dist'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './components/ShipmentForm/schema.yup'
import { useStyles } from './styles'
import { OrderSummary } from './components/OrderSummary'
import { ShipmentForm } from './components/ShipmentForm'
import { CircularProgress } from '../../components/CircularProgress'
import { ModalWrapper } from '../../components/ModalWrapper'
import { useFetchProposalQuotation } from 'domains/proposal/proposal-quotation'
import { useFetchCardFee } from 'domains/admin/card_fee/hooks/useFetchCardFee'
import { type CheckoutModalProps } from './CheckoutModal.types'
import { SuccessOrderModal } from './components/Modals'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { INITIAL } from './constants'
import { useClearErrors } from './components/ShipmentForm/hooks/useClearErrors'

function CheckoutModal({
  openModal,
  cotacaoId,
  onCloseModal,
}: CheckoutModalProps): JSX.Element {
  const classes = useStyles()

  const orderId = useCheckoutModalStore((state) => state.orderId)
  const paymentLink = useCheckoutModalStore((state) => state.paymentLink)
  const paymentMethodSlug = useCheckoutModalStore(
    (state) => state.paymentMethodSlug,
  )

  const { proposalQuotationData, isLoading } =
    useFetchProposalQuotation(cotacaoId)

  const { cardFeeData } = useFetchCardFee()

  const { ...methods } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      deliveryAddress: INITIAL.DELIVERY_ADDRESS,
      billingAddress: INITIAL.BILLING_ADDRESS,
      paymentMethod: 'billet',
      kitValues: {
        laborValue: '0',
        equipmentsValue: 0,
        totalValue: 0,
        creditCardCharges: 0,
        cardFee: 0,
        installments: '1',
        hasInsurance: true,
      },
    },
  })

  useClearErrors({
    methods,
  })

  if (orderId)
    return (
      <SuccessOrderModal
        isOpen
        onClose={onCloseModal}
        orderId={orderId}
        paymentLink={paymentLink}
        paymentTypeSlug={paymentMethodSlug}
      />
    )

  return (
    <ModalWrapper
      id="modal-checkout-equipment"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      isOpen={openModal}
    >
      <>
        {isLoading && <CircularProgress />}
        {proposalQuotationData && cardFeeData && (
          <div className={classes.paper}>
            <div className={classes.container}>
              <CloseXIcon
                onClick={onCloseModal}
                className={classes.closeXIcon}
              />
              <FormProvider {...methods}>
                <OrderSummary
                  data-testid="kit-container"
                  kit={proposalQuotationData.kit}
                  equipmentsValue={
                    proposalQuotationData.quotation.equipment_value
                  }
                  laborValue={proposalQuotationData.quotation.labor_value}
                  cardFee={cardFeeData.card_fee.toString()}
                />
                <ShipmentForm
                  quotation={proposalQuotationData.quotation}
                  proposalId={proposalQuotationData.dimensioning.proposal_id}
                  isCustomer={!!proposalQuotationData.customer_id}
                  kit={proposalQuotationData.kit}
                  quotationId={cotacaoId}
                  onCloseCheckoutModal={onCloseModal}
                  methods={methods}
                  cardFee={cardFeeData}
                />
              </FormProvider>
            </div>
          </div>
        )}
      </>
    </ModalWrapper>
  )
}

export default CheckoutModal
