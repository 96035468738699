export enum Features {
  RANKING77 = 'ranking-77',
  DASHBOARD = 'dashboard',
  PROJETOS = 'projetos',
  FINANCIAMENTOS = 'financiamentos',
  PEDIDOS = 'pedidos',
  CALENDARIO = 'calendario',
  NOTIFICACOES = 'notificacoes',
  COTACAO = 'cotacao',
  PROPOSTA = 'proposta',
  CONFIGURACOES = 'configuracoes',
  MEUTIME = 'meu-time',
  EQUIPAMENTOS = 'equipamentos',
  UNIVERSIDADE77 = 'universidade-77',
  HOMOLOGACAO = 'homologacao-77',
  PAGAMENTOS = 'pagamentos',
}
