import styled from 'styled-components'
import { palette } from '@77sol/styles'

export const Container = styled.section`
  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 16px 0;
  }

  .form-grid {
    gap: 16px;
  }
`

export const CopyAddress = styled.label`
  cursor: pointer;

  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  margin: 16px 0;

  font-size: 0.9rem;
  color: ${palette.grayscale[500]};
`
