import styled from 'styled-components'
import { fontSize } from '@77sol-ui/tokens'

export const Container = styled.div`
  h2 {
    font-size: ${fontSize.sm};
  }

  p {
    font-size: ${fontSize.xs};
  }

  padding-top: 10px;
`
