import {
  GraduationCap,
  BookCheck,
  TruckIcon,
  Home,
  Landmark,
  Layers,
  Trophy,
  Zap,
  UserCircle2,
  Users,
} from 'lucide-react'
import { FeaturesRoutes } from 'acl/config/routes.types'
import { type INavbarItem } from 'layouts/Dashboard/components/NavBar/components/NavbarItems/types'
import { type INewSidebarNavMenuItems } from 'components/Sidebar/Desktop/constants/integratorNavbarItems'

const INTEGRATOR_SIDEBAR_MOBILE_ITEMS: INewSidebarNavMenuItems[] = [
  {
    title: 'Início',
    href: FeaturesRoutes.DASHBOARD,
    icon: Home,
  },
  {
    title: 'Projetos',
    href: FeaturesRoutes.PROJETOS,
    icon: Layers,
  },
  {
    title: 'Financiamentos',
    href: FeaturesRoutes.FINANCIAMENTOS,
    icon: Landmark,
  },
  {
    title: 'Meus pedidos',
    href: FeaturesRoutes.PEDIDOS,
    icon: TruckIcon,
    signalType: 'orders',
  },
]

const ECOSYSTEM_GROUP_ITEMS: INavbarItem[] = [
  {
    title: 'Ranking 77',
    href: FeaturesRoutes.RANKING77,
    icon: Trophy,
  },
  {
    title: 'Universidade',
    href: FeaturesRoutes.UNIVERSIDADE77,
    icon: GraduationCap,
  },
  {
    title: 'Homologação',
    href: FeaturesRoutes.HOMOLOGACAO,
    icon: BookCheck,
  },
  {
    title: 'Equipamentos',
    href: FeaturesRoutes.EQUIPAMENTOS,
    icon: Zap,
  },
]

const CONFIGS_GROUP_ITEMS: INavbarItem[] = [
  {
    title: 'Minha Conta',
    href: FeaturesRoutes.CONFIGURACOES,
    icon: UserCircle2,
  },
  {
    title: 'Meu Time',
    href: FeaturesRoutes.MEUTIME,
    icon: Users,
  },
]

export {
  INTEGRATOR_SIDEBAR_MOBILE_ITEMS,
  ECOSYSTEM_GROUP_ITEMS,
  CONFIGS_GROUP_ITEMS,
}
